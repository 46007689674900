import { reactive } from 'vue'
// import index from '../views/index.vue'
// import { createRouter, createWebHistory } from "vue-router";
import { createRouter, createWebHashHistory } from "vue-router";
import { getToken } from '../utils/auth';

// 溯源码
const traceability = () => import("../views/traceability.vue")
//餐厅评价
const evaluate = () => import("../views/evaluate.vue")
const routes = [
    {
        path: '',
        name: 'traceability',
        redirect: 'traceability',
        children: [{
            path: 'traceability',
            // component: () => import('@/views/index')
            component: traceability,
        }]
    },
    {
        name: 'evaluate',
        redirect: '/evaluate',
        children: [{
            path: '/evaluate',
            // component: () => import('@/views/index')
            component: evaluate,
        }]
    },
]

var router = createRouter({
    mode: 'history',
    // history: createWebHistory(),
    history: createWebHashHistory(),
    routes: routes
})

router.beforeEach(async (to, from, next) => {
    console.log(to, from, '==', getToken());
    const data = JSON.stringify(to.query)
    localStorage.setItem("query", data);
    next()

    return
    if (to.path === "/") {
        console.log(24234);
        next()
    } else {
        var token = getToken()
        console.log(token, '2342');
        if (token) {
            // var store = mainStore()
            // if(!store.userInfo){
            //     router.replace("/Login")//有token, 但没有userInfo，也要跳登录页面
            // }else{
            console.log(2342342, to);
            next()
            // }
        } else {
            // next()

            // 没有token 可以访问溯源码
            if (to.path === '/') {
                next()
            } else {
                // next()

                //没有token，跳到登录
                // return
                router.replace('/')
                console.log(234234, '登录', localStorage);
            }

        }

    }
})

console.log("router里面")
export { router }
