import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import { router } from './router/router'

// 2. 引入组件样式
import 'vant/lib/index.css';
import { Button } from 'vant';
import {Loading ,Badge, Step,Tab, Tabs ,Empty , Steps,Divider, Switch,Dialog , Cell,Popup , RadioGroup, Radio, Uploader , Tabbar, Icon ,Toast,Search ,Stepper , DatePicker , SwipeCell, TabbarItem,Form, Field, CellGroup,Notify, DropdownMenu, DropdownItem,Image as VanImage,ImagePreview  } from 'vant';
import { createPinia } from 'pinia';
import piniaPluginPersist from 'pinia-plugin-persist'
import 'lib-flexible/flexible.js'
import vConsole from './utils/vconsole'
import {IsPC} from './utils/util'
// import Drop from './components/drop/Drop';



var app = createApp(App)
app.use(router)
app.use(Empty);
app.use(Dialog);
app.use(Divider);
app.use(Tab);
app.use(Tabs);
app.use(Step);
app.use(Steps);
app.use(Button);
app.use(Tabbar);
app.use(TabbarItem);
app.use(Notify);
app.use(Form);
app.use(Field);
app.use(CellGroup);
app.use(Toast);
app.use(Search);
app.use(Stepper);
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(SwipeCell);
app.use(DatePicker);
app.use(Icon);
app.use(Uploader);
app.use(Loading);
app.use(Popup);
app.use(Cell);
app.use(CellGroup);
app.use(Radio);
app.use(RadioGroup);
app.use(Switch);
app.use(Badge);
app.use(VanImage);
app.use(ImagePreview);

const pinia = createPinia()
pinia.use(piniaPluginPersist)

app.use(pinia);

// app.use(Drop);


app.mount('#app')





if(process.env.NODE_ENV === "production"){
    // document.getElementById("__vconsole").style.display = "none"
}
document.getElementById("__vconsole").style.display = "none"


var date = new Date()
var str = date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + `:${date.getSeconds()}`
console.log("今天日期", str)

date.setDate(date.getDate() + 100)
console.log("日期+100天", date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + `:${date.getSeconds()}`)

var str = "22"
console.log(str.split("."))