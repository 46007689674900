export function setBodyBg(val) {
    console.log(document.getElementsByName("body"))
    document.getElementsByTagName("body")[0].style.background = val
}

/**
 * 获取窗口滚动距离
 * @returns 
 */
export function getWinScrollTop(){
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    return scrollTop
}

/**
 * 获取浏览器高度
 */
export function getWinHeight(){
    return window.innerHeight
}

export function getScrollTop(element){
    if(element === window){
        return getWinScrollTop()
    }else{
        return element.scrollTop
    }
}

export function getHeight(element){
    return element.offsetHeight
}

export function deepClone(obj){
    let _tmp = JSON.stringify(obj);//将对象转换为json字符串形式
    let result = JSON.parse(_tmp);//将转换而来的字符串转换为原生js对象
    return result;
}

//禁止页面滚动
export function lockPageScroll(){
    document.getElementsByTagName("html")[0].style.overflow="hidden"
}

//恢复页面滚动
export function unlockPageScroll(){
    document.getElementsByTagName("html")[0].style.overflow="auto"
}

export function IsPC () {
    var userAgentInfo = navigator.userAgent;
    var Agents = ["Android", "iPhone",
        "SymbianOS", "Windows Phone",
        "iPad", "iPod"];
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false;
            break;
        }
    }
    return flag;
}


export function debounce(func, wait) {
    //定时器变量
    var timeout;
    return function () {
        //每次触发scrolle，先清除定时器
        clearTimeout(timeout);
        //指定多少秒后触发事件操作handler
        timeout = setTimeout(func, wait);
    };
};

export async function multNetGetLast(netFun){
    
    return netFun()
    
}

//给数组的副本排序，返回列表里第一个
export function getMaxItem(list, callback){
    var cloneList = deepClone(list)
    var arr = cloneList.sort(callback)
    return arr[0]
}

//把一个数组中最大的拍到第一个
export function maxMovetoTop(list, key){
    if(list.length == 0){
        return
    }
    var maxIndex = 0
    var value;
    for(var i = 0; i < list.length; i++){
        if(i == 0){
            maxIndex = i
            value = list[i][key]
        }else{
            if(list[i][key] > value){
                value = list[i][key]
                maxIndex = i
            }
        }
    }

    var temp = list[maxIndex]
    list[maxIndex] = list[0]
    list[0] = temp
}

/**
 * 返回排序后的数组，不改变原数组
 */
export function getSortedList(list, callback){

    var arr = []
    list.forEach((val)=>{
        arr.push(val)
    })

    arr.sort(callback)
    return arr

}

/**
 * 判断小数点后面有几位数
 */
export function dotCount(str){
    if(str.indexOf(".") > 0){
        var arr = str.split(".")
        // arr[1]
    }else{
        return str
    }
     
    
}

export function nodeContain(parentNode, sonNode){
    if (parentNode === sonNode) return true;

    var p = sonNode
    console.log(p)

    while(p.parentNode){
        if(p.parentNode == parentNode){
            return true
        }
        p = p.parentNode
        console.log(p)
    }
    return false
  
    // var p = sonNode.parentNode;
    // if (p !== parentNode){
    //   return nodeContain(parentNode, p);
    // }
    // else{
    //   return true;
    // }
  }


  /**
   * 时间戳转Date对象
   */
  export function timestamp2Date(str){
    var date = new Date(str)
    return date
  }

  /**
   * 时间戳转成xxxx-x-x
   */
  export function timestamp_2_DateStr(str){
    var date = new Date(str)
    return date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate()
  }

/**
  * 一个时间戳转成下一天的时间戳
  */
export function timestamp_to_nextDaystamp(str){
    var date = new Date(str)
    var d = date.getDate()
    date.setDate(d+1)
    return date.getTime()
}

/**
  * 一个时间戳转成下一天的时间戳
  */
export function timestamp_to_preDaystamp(str){
    var date = new Date(str)
    var d = date.getDate()
    date.setDate(d-1)
    return date.getTime()
}

//判传入2个时间戳，返回b是否是a的下一天
export function isTomorow(a, b){
    var dateA = new Date(a)
    var dateB = new Date(b)

    
    dateA.setDate( dateA.getDate()+1 )

    // date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate()
    if(dateA.getFullYear() == dateB.getFullYear() 
       && dateA.getMonth() == dateB.getMonth() 
       && dateA.getDate() == dateB.getDate()
    ){
        return true
    }
    return false

}

//判断2个时间戳是否是同一天
export function isSameDay(a, b){
    var dateA = new Date(a)
    var dateB = new Date(b)
    if(dateA.getFullYear() == dateB.getFullYear() 
       && dateA.getMonth() == dateB.getMonth() 
       && dateA.getDate() == dateB.getDate()
    ){
        return true
    }
    return false
}

/**
 * 时间戳返回 [2023, 05, 06] 的形式的数组, 小于10的前面要加0， 三个数都是字符串
 * @param {*} t 
 */
export function timestamp2DateArr(t){
    var date = new Date(t)

    var y = date.getFullYear() + ''

    var m = date.getMonth() + 1
    if(m < 10){
        m = "0" + m
    }
    m += ''

    var d = date.getDate()
    if(d < 10){
        d = '0' + d
    }
    d += ''

    return [y, m, d]

}

/**
 * xxxx-xx-xx转成 [2023, 05, 06] 的形式的数组, 小于10的前面要加0， 三个数都是字符串
 */
export function yyyyMMdd_DateAee(str){
    var arr = str.split("-")
    var y = arr[0]
    var m = arr[1]
    var d = arr[2]

    if(m.length == 1){
        m = '0' + m
    }
    if(d.length == 1){
        d = '0' + d
    }
    return [y, m, d]
}

export function getQueryVariable(variable){
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split("=");
        if(pair[0] == variable){return pair[1];}
    }
    return(false);
}