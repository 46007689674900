import Cookies from 'js-cookie'
import { useRouter } from 'vue-router'
import { isWebview } from '../service/EnvManager'
import { getQueryVariable } from './util'

const TokenKey = 'Admin-Token'
const UserIdKey = 'userId'

const ExpiresInKey = 'Admin-Expires-In'

var router = useRouter()

export function getToken() {
  if(isWebview()){
    return getQueryVariable("token")
  }else{
    return Cookies.get(TokenKey)
  }
}

export function setToken(token) {
  if(isWebview()){

  }else{
    return Cookies.set(TokenKey, token)
  }
}

export function getUserId(){

  if(isWebview()){
      return getQueryVariable("userId")
    
  }else{
    if(Cookies.get(UserIdKey)){
      return parseInt(Cookies.get(UserIdKey))
    }else{
      router.push("/Login")
    }
  }

  
  
}

export function setUserId(userId){
  return Cookies.set(UserIdKey, userId)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function removeUserId(){
  return Cookies.remove(UserIdKey)
}

export function getExpiresIn() {
  return Cookies.get(ExpiresInKey) || -1
}

export function setExpiresIn(time) {
  return Cookies.set(ExpiresInKey, time)
}

export function removeExpiresIn() {
  return Cookies.remove(ExpiresInKey)
}
