import {getQueryVariable} from '../utils/util'

export var isWebview = ()=>{
    

    var isWebView = getQueryVariable("isWebView")
    if(isWebView == "1"){
        return true
    }
    return false


}